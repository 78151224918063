/* eslint-disable no-self-compare */
/* eslint-disable no-constant-condition */

export const fbEvent = (name, options = {}) => {
  window.fbq('track', name, options);
};

export const trackEvent = (name, options = {}) => {
  if (window.gtag) {
    window.gtag('event', name, options);
  }
};

export const trackTikTokEvent = (name, options = {}) => {
  if (window.ttq) {
    window.ttq.track(name, options);
  }
};

export const linkedinEvent = (name, options = {}) => {
  if (window.lintrk) {
    window.lintrk(name, options);
  }
};

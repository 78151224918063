import React, { useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';
import { Container, Row, Col } from 'react-bootstrap';
import style from './style.module.css';
import NotesBlock from './NotesBlock';
import { getVideo, setVideoWatchCount } from '../../../Services/Videos';
import { useApi } from '../../../contexts/RequestContext';
import { useHistory } from 'react-router-dom';
import ShereBtn from '../../../components/ShereBtn';
import './style.css';
import { updateProfileVideosTime1 } from '../../../Services/Profile';
import { useAuth } from '../../../contexts/AuthContext';
import { Player } from 'react-tuby';
import 'react-tuby/css/main.css';
import ReactHlsPlayer from 'react-hls-player/dist';
import { fbEvent, trackEvent, trackTikTokEvent } from '../../../libs/pixels';

const HeaderWatch = ({ slug }) => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const dakika = urlParams.get('dakika');
  const { videosList, teacher } = useApi();
  const { profile } = useAuth();
  const [data, setData] = useState(null);
  const [nextBtn, setNextBtn] = useState(false);
  const [playVideo, setPlayVideo] = useState('');
  const [videoOrder, setVideoOrder] = useState(0);
  const [videoTime, setVideoTime] = useState('00:00:00');
  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const playerRef = useRef(null);
  const params = window.location.pathname;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      setIsLoading(true);
      if (playerRef.current) {
        playerRef.current.pause();
      }
      getVideo(slug).then((res) => {
        setData(res);
        setNextBtn(false);
        setVideoOrder(res.data.order);
        setLastUpdateTime(0);
        if (res.data?.file) setPlayVideo(res.data?.file);

        setTimeout(() => {
          if (playerRef.current) {
            const currentDuration = moment.duration(res?.data?.current_duration).asSeconds();
            if (dakika) {
              playerRef.current.currentTime = moment.duration(dakika).asSeconds();
              fbEvent('ResumeContent', {
                content_name: res?.data?.name,
                content_ids: [res?.data?.id],
              });
              trackEvent('resume_content', {
                content_id: res?.data?.id,
              });
              trackTikTokEvent('ResumeContent', {
                content_name: res?.data?.name,
                content_ids: [res?.data?.id],
              });
            } else if (playerRef.current.duration - currentDuration <= 10) {
              fbEvent('ViewContent', {
                content_name: res?.data?.name,
                content_type: 'video',
                content_ids: [res?.data?.id],
              });
              trackEvent('view_item', {
                content_id: res?.data?.id,
              });
              trackTikTokEvent('ViewContent', {
                content_name: res?.data?.name,
                content_type: 'video',
                content_ids: [res?.data?.id],
              });
              playerRef.current.currentTime = 0;
            } else {
              playerRef.current.currentTime = currentDuration;
              fbEvent('ViewContent', {
                content_name: res?.data?.name,
                content_type: 'video',
                content_ids: [res?.data?.id],
              });
              trackEvent('view_item', {
                content_id: res?.data?.id,
              });
              trackTikTokEvent('ViewContent', {
                content_name: res?.data?.name,
                content_type: 'video',
                content_ids: [res?.data?.id],
              });
            }
            playerRef.current.play();
            setIsLoading(false);
          }
        }, 1000);

        setVideoWatchCount(res?.data?.slug);
      });
    }
  }, [slug]);

  const handleTimeUpdate = useCallback((e) => {
    const currentTime = e.target.currentTime;
    const date = new Date(0)
    date.setSeconds(e.target.currentTime);
    setVideoTime(date.toISOString().substr(11, 8));

    if (Math.floor(currentTime / 10) > lastUpdateTime) {
      setLastUpdateTime(Math.floor(currentTime / 10));
      updateProfileVideosTime1(data?.data?.id, { duration: videoTime }, profile?.id);
    }

    if (e.target.duration - currentTime <= 10 && videosList?.videos?.length > videoOrder) {
      setNextBtn(true);
    }
    if (e.target.duration - 1 <= currentTime) {
      nextVideo();
    }
  }, [lastUpdateTime, videoTime, data, profile, videosList, videoOrder, slug]);


  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.addEventListener('timeupdate', handleTimeUpdate);
      const handleFullscreenChange = (e) => {
        var element = document.getElementById('next-btn');

        if (document.fullscreenElement) {
          element.style.position = 'fixed';
          element.style.zIndex = '99999';
          // var parent = document.getElementsByClassName("tuby-container");
          // if (parent) parent.appendChild(element);
        } else {
          element.style.position = 'absolute';
        }
      }
      document?.addEventListener('fullscreenchange', handleFullscreenChange);
      return () => {
        if (playerRef.current) {
          playerRef.current.removeEventListener('timeupdate', handleTimeUpdate);
        }
        document.removeEventListener('fullscreenchange', handleFullscreenChange);
      };
    }
  }, [handleTimeUpdate]);

  const nextVideo = () => {
    setNextBtn(false);
    history.push(`/${teacher?.slug}/${videosList?.slug}/${videosList?.videos[videoOrder]?.slug}`);
  };

  const videoHandler = (control) => {
    if (control === 'play') {
      playerRef.current.play();
    } else if (control === 'pause') {
      playerRef.current.pause();
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col xs="12" sm="12" md="8" className="mb-3">
          <dvi className="mb-5">
            <div className={style.wsAbout}>
              {nextBtn && (
                <>
                  {' '}
                  <div onClick={nextVideo} id="next-btn" className={`${style.wsNext} tilt-in-fwd-tr cursor-pointer`}>
                    <i class="fa fa-play mr-3"></i> Sonraki Ders
                  </div>
                </>
              )}
              <Player
                playerRef={playerRef}
                width={'100%'}
                height={'100%'}
                src={[
                  {
                    quality: 'Auto',
                    url: playVideo,
                  },
                ]}
                autoPlay={true}
                keyboardShortcut={false}
              >
                {(ref, props) => <ReactHlsPlayer playerRef={ref} {...props} />}
              </Player>

            </div>
          </dvi>
          <div className="d-flex justify-content-between align-item-center flex-nowrap mt-4">
            <div className="text-left mt-1 mt-md-3 mb-1 mb-md-3">
              <h5 className="d-flex align-item-center h5">{data?.data?.name}</h5>
              {/* <span>{data?.data?.description?.slice(0, 30)}...</span> */}
            </div>
            <div className="d-flex mt-3 mb-3">
              {/* <Link
                  onClick={downloa}
                  className="
                      btn-link d-flex align-items-center mr-3 cursor-pointer"
                >
                  <img className={style.icons} src={noteIcon} />{" "}
                  <span>Ders Notları</span>
                </Link> */}
              {/* <Link className="d-flex align-items-center mr-3  btn-link">
                  <img className={style.icons} src={downlaodIcon} />
                  <span>İndir</span>
                </Link> */}
              <div className="mt-0">
                <ShereBtn link={params} title={data?.data?.name} content={data?.data?.description} type={true} />
              </div>
            </div>
          </div>
        </Col>
        <Col xs="12" sm="12" md="4">
          {data && !isLoading && <NotesBlock item={data?.data} time={videoTime} videoHandler={videoHandler} />}
        </Col>
      </Row>
    </Container>
  );
};

export default HeaderWatch;

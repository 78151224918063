import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './style.css';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { checkGiftCode } from '../../Services/Gift';
import { useAuth } from '../../contexts/AuthContext';
const CardSchema = Yup.object().shape({
    discount_code: Yup.string(),
});

const initialValues = {
    discount_code: '',
};

const UseGiftCoupone = ({ handleClose }) => {
    let history = useHistory();
    const [couponeCode, setCouponeCode] = useState('');
    const [hasCode, setHasCode] = useState(false);
    const { setGiftCodeFunc } = useAuth();

    const contactUs = () => {
        window.open('https://0u0c51hhbc2.typeform.com/to/NATfnS4l', '_blank');
    };
    const formik = useFormik({
        initialValues,
        validationSchema: CardSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setGiftCodeFunc(couponeCode);
            history.push(`/coupone-sign-up/${couponeCode}`);
            handleClose();
            // notify('Başarı İle Oluşturuldu')
        },
    });
    const handleCouponeCode = (e) => {
        setCouponeCode(e.target.value);
        const body = {
            code: e.target.value,
        };
        checkGiftCode(body).then((res) => {
            setHasCode(res?.success);
        });
    };

    return (
        <>
            <div className="gift-card-bg border-0 d-flex justify-content-start align-items-center">
                <div className="gift-form-info">
                    <div className="sign-in-page-data">
                        <div className="sign-in-from w-100 m-auto gift-form-info-content text-left">
                            <h4 className="h3 mb-3">Hediyenizi kullanın</h4>
                            <div className="text-left mb-3">Başlamak için, kodu aşağıya girin.</div>
                            <Form onSubmit={formik.handleSubmit} noValidate>
                                <Row>
                                    <Col sm="8">
                                        <Form.Group className="kod gift-form-group">
                                            {/* <Form.Label className="text-white mb-2">Kupon Kodu</Form.Label> */}
                                            <Form.Control
                                                type="text"
                                                className={`form-control  mb-0 ${couponeCode?.length > 0 ? (hasCode ? 'valid-code' : 'invalid-code') : ''}`}
                                                id="exampleInputcard_discount_code"
                                                placeholder="Kod veya Pin"
                                                name="discount_code"
                                                {...formik.getFieldProps('discount_code')}
                                                autoComplete="off"
                                                value={couponeCode}
                                                onChange={(e) => handleCouponeCode(e)}
                                            />
                                            {couponeCode?.length > 0 ? (
                                                !hasCode ? (
                                                    <div>
                                                        <span className="invalid-code-icon">
                                                            <i className="fa fa-exclamation-circle" />
                                                        </span>
                                                        <span className="text-primary" role="alert">
                                                            Kupon kodu geçersiz
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <span className="valid-code-icon">
                                                        <i className="fa fa-check-circle" />
                                                    </span>
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className='d-flex justify-content-start'>
                                    <button
                                        // onClick={nextStep}
                                        type="submit"
                                        className="btn btn-hover btn-primary1 my-2 w-50 text-center"
                                        style={{ textTransform: 'none' }}
                                        disabled={!hasCode}
                                    >
                                        Kullan
                                    </button>
                                </div>
                                <div className=''>
                                    <p className='my-4 text-secondary' style={{ fontSize: '14px' }}>Hediye kupon kodları hakkında yardım ister misiniz?</p>
                                    <p className='text-secondary' style={{ fontSize: '14px' }}> <span className='text-primary cursor-pointer' onClick={contactUs}>Bize Ulaşın</span></p>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>

                <div className="bg-image">
                    <div className="bg-image-overlay"></div>
                    {/* <img src={require('../../assets/images/bg/r3.jpg')} alt="gift-card" /> */}
                </div>

            </div>


        </>
    );
};

export default UseGiftCoupone;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import bgImg from '../../../assets/images/bg/r3.jpg';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../../contexts/AuthContext';
import { Auth, activateAccount } from '../../../Services/AuthApi';
import './style.css';
import { notify, notifyError } from '../../../components/AlartNotify/AlertNotify';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Lütfen E-posta adresi gir.')
    .min(3, 'En az 3 karakter olmalı!')
    .max(50, 'En fazla 50 karakter olmalı!')
    .required('Lütfen e-posta adresini gir.'),
  password: Yup.string().min(6, 'En az 6 karakter olmalı!').max(50, 'En fazla 50 karakter olmalı!').required('Lütfen şifreni gir'),
});

const initialValues = {
  email: '',
  password: '',
};

const Login = (props) => {
  let history = useHistory();
  const { login } = useAuth();
  const [isLogin, setIslogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isCheck, setIsCheck] = useState(false);
  const [password, setPassword] = useState('');
  const [eye, setEye] = useState('password');

  const handlerChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value.match('^(?=.*[a-z])(?=.*[0-9])(?=.{6,})') != null) {
      setIsCheck(true);
    } else {
      setIsCheck(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setIslogin(false);
      setIsLoading(true);
      Auth(values)
        .then((data) => {
          login(data);
          history.push('/who-is-watch');
        })
        .catch((err) => {
          console.log(err.response.data);
          setIslogin(true);
          if (err?.response?.data?.message === 'Hesabınız kapatılmış durumda!') {
            Swal.fire({
              text: 'Üyeliğiniz iptal edilmiştir. Üyeliğinizi aktifleştirmek ister misiniz?',
              confirmButtonColor: '#be161a',
              confirmButtonText: 'Evet',
              cancelButtonText: 'İptal',
              background: '#14161A',
              color: '#fff',
              showCancelButton: true,
              customClass: {
                closeButton: 'btn btn-outline-light',
              },
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                activateAccount(values).then(() => {
                  setIslogin(false);
                  notify('Hesabınız tekrar aktif edildi.');
                  Auth(values).then((data) => {
                    login(data);
                    history.push('/who-is-watch');
                  });
                });
              } else if (result.isDenied) {
              }
            });
          }
          notifyError(err?.response?.data?.message);
          setError(err?.response?.data?.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  return (
    <>
      <section className="sign-in-page slick-bg bg-title" style={{ backgroundImage: `url(${bgImg})` }}>
        <Container>
          <Row className="justify-content-start align-items-center" style={{ marginTop: '10%' }}>
            <Col lg="5" md="12" className="align-self-center text-left">
              <div className="sign-user_card ">
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    {isLogin && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}
                    <h1 className="h2 mb-3 text-center">Giriş Yap</h1>
                    <h6 className="mb-3 h6 text-center">Hesabına e-posta ve şifre kullanarak giriş yapabilirsin.</h6>
                    <Form className="mt-4" onSubmit={formik.handleSubmit} noValidate>
                      <Form.Group>
                        <Form.Control
                          type="email"
                          {...formik.getFieldProps('email')}
                          name="email"
                          className="form-control mb-0"
                          id="exampleInputEmail1"
                          placeholder="E-posta Adresi"
                          autoComplete="off"
                          required
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="fv-plugins-message-container">
                            <span className="text-primary" role="alert">
                              {formik.errors.email}
                            </span>
                          </div>
                        )}
                      </Form.Group>
                      <Form.Group className="position-relative">
                        <Form.Control
                          type={eye}
                          {...formik.getFieldProps('password')}
                          name="password"
                          className="form-control mb-0"
                          id="exampleInputPassword2"
                          placeholder="Şifreniz"
                          required
                        />
                        <div className="password-eye">
                          {eye === 'text' ? (
                            <i onClick={() => setEye('password')} class="fa fa-eye-slash"></i>
                          ) : (
                            <i onClick={() => setEye('text')} class="fa fa-eye"></i>
                          )}
                        </div>
                        {formik.touched.password && formik.errors.password && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span className="text-primary" role="alert">
                                {formik.errors.password}
                              </span>
                            </div>
                          </div>
                        )}
                      </Form.Group>
                      <div className="sign-info mb-3">
                        <div></div>
                        {/* <div className="custom-control custom-checkbox d-inline-block">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck"
                          >
                            Beni Hatırla
                          </label>
                        </div> */}
                        <div className="d-flex justify-content-center links">
                          <Link to="/send-mail" className="f-link text-primary">
                            Şifremi Unuttum
                          </Link>
                        </div>
                      </div>
                      <div>
                        <Button type="submit" disabled={isLoading} className="btn btn-hover btn-primary1 w-100">
                          Giriş Yap
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="d-flex justify-content-center links text-muted">
                    Üye Değil misin?
                    <Link to="/sign-up" className=" ml-2">
                      Hemen Üye Ol
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Login;

import React, { useEffect, useState } from 'react';
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import bgImg from '../../../assets/images/bg/r3.jpg';
import './style.css';
import PasswordStrengthMeter from './components/PasswordControl';
import { useAuth } from '../../../contexts/AuthContext';
import { Reg2 } from '../../../Services/AuthApi';
import { trackEvent } from '../../../libs/pixels';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ToastContainer, toast } from 'react-toastify';

const SetPassword = (props) => {
  let history = useHistory();
  const location = useLocation();
  const queryParams = window.location.pathname.includes('mobile');
  const { regId } = useAuth();
  const [isCheck, setIsCheck] = useState(false);
  const [password, setPassword] = useState('');
  const [eye, setEye] = useState('password');
  const hasCoupone = location.pathname == '/set-password/wise25' ? true : false;
  const hasAskCoupone = location.pathname == '/set-password/ask25' ? true : false;
  const handlerChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value.match('^(?=.*[a-z])(?=.*[0-9])(?=.{6,})') != null) {
      setIsCheck(true);
    } else {
      setIsCheck(false);
    }
  };

  const nextStep = (e) => {
    e.preventDefault();
    Reg2(regId?.id, { password: password })
      .then((data) => {
        if (queryParams) history.push('/mobile/select-plan');
        else if (hasCoupone) history.push('/select-plan/wise25');
        else if (hasAskCoupone) history.push('/select-plan/ask25');
        else history.push('/select-plan');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const notifySuccess = (text) => {
    toast.success(text, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };
  const handleCopy = () => {
    const discountCode = 'WISE25'; // Kopyalanacak metin
    navigator.clipboard
      .writeText(discountCode)
      .then(() => {
        notifySuccess('Kod kopyalandı: ' + discountCode);
      })
      .catch((err) => {
        console.error('Kopyalama başarısız:', err);
      });
  };
  const handleCopyAsk = () => {
    const discountCode = 'ASK25'; // Kopyalanacak metin
    navigator.clipboard
      .writeText(discountCode)
      .then(() => {
        notifySuccess('Kod kopyalandı: ' + discountCode);
      })
      .catch((err) => {
        console.error('Kopyalama başarısız:', err);
      });
  };

  useEffect(() => {
    trackEvent('conversion_event_begin_checkout_4', {});
  }, []);

  return (
    <>
      <section className="sign-in-page slick-bg" style={{ backgroundImage: `url(${bgImg})` }}>
        <Container>
          <Row className="justify-content-center align-items-center " style={{ marginTop: '10%' }}>
            <Col lg="7" md="12" className="align-self-center text-left">
              <div className="sign-user_card ">
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    {hasCoupone && (
                      <div className="btn btn-success  text-center mb-3 w-100 cursor-pointer " onClick={handleCopy}>
                        {' '}
                        <strong className="text-white">
                          %25 İNDİRİM KODU: <span className="h5">WISE25</span>
                        </strong>{' '}
                      </div>
                    )}
                    {hasAskCoupone && (
                      <div className="btn btn-success  text-center mb-3 w-100 cursor-pointer " onClick={handleCopyAsk}>
                        {' '}
                        <strong className="text-white">
                          %25 İNDİRİM KODU: <span className="h5">ASK25</span>
                        </strong>{' '}
                      </div>
                    )}
                    <h6 className="mb-3 text-primary text-center">Adım 2/4</h6>
                    <h1 className="h3 mb-4 text-center">Şifreni Oluştur</h1>
                    {/* <h6 className="mb-3 text-center">Şifreni Oluştur</h6> */}
                    <Form noValidate>
                      <Row>
                        <Col md="12">
                          <Form.Group>
                            <Form.Control
                              type={eye}
                              className="form-control mb-0"
                              id="exampleInputEmail2"
                              onChange={(e) => handlerChange(e)}
                              name="password"
                              placeholder="Şifrenizi Oluşturun"
                              autoComplete="off"
                              required
                            />
                            <div className="password-eye1">
                              {eye === 'text' ? (
                                <i onClick={() => setEye('password')} class="fa fa-eye-slash"></i>
                              ) : (
                                <i onClick={() => setEye('text')} class="fa fa-eye"></i>
                              )}
                            </div>
                          </Form.Group>
                          {!isCheck && password.length > 1 && (
                            <div className="fv-plugins-message-container">
                              <span className="text-primary" role="alert">
                                Lütfen belirtilen kurallara göre şifrenizi oluşturun.
                              </span>
                            </div>
                          )}
                          <PasswordStrengthMeter password={password} />
                        </Col>

                        <Col sm="12" className="mt-2 mb-4">
                          <div className="custom-control custom-checkbox d-inline-block">
                            Şifreniz en az 6 karakter uzunluğunda olmalı ve en az bir harf ile bir sayı içermelidir.
                          </div>
                        </Col>
                      </Row>

                      <Button disabled={!isCheck} onClick={nextStep} type="submit" className="btn btn-hover btn-primary1 my-2 w-100">
                        Devam Et
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SetPassword;
